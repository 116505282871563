// используйте для скрытия нежелательных анимаций-скачков при поворотах экрана
@keyframes disableBreakpointAnimation {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  100% {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes move {
  to {
    background-position-x:
      calc(-6*100%/3),
      calc(-3*100%/3),
      calc(0*100%/3),
      calc(3*100%/3);
  }
}

@keyframes down {
  0% {
    background-position-y: 105%;
  }

  100% {
    background-position-y: 0%;
    animation-play-state: paused;
  }
}

@keyframes move-chevron {
  25% {
   opacity: 1;
  }
  33.3% {
   opacity: 1;
   transform: translateY(0.7rem * 3.8);
  }
  66.6% {
   opacity: 1;
   transform: translateY(0.7rem * 5.2);
  }
  100% {
   opacity: 0;
   transform: translateY(0.7rem * 8) scale(0.5);
  }
 }
