/* stylelint-disable  function-no-unknown*/

.intro {
  padding-top: size(328px);

  @include vp-767 {
    padding-top: size(298px);
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__tagline {
    max-width: size(932px);
    margin: 0 auto;
    margin-top: size(-15px);
    margin-bottom: size(36px);

    text-align: center;

    @include vp-767 {
      margin-top: size(-22px);
      margin-bottom: size(24px);
    }
  }

  &__flow-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    position: relative;
    width: max-content;
    margin: 0 auto;
  }

  &__title-wrapper {
    position: relative;
    z-index: 2;

    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    max-height: size(41px);
    padding: 0 size(6px);

    margin: 0 auto;

    background-color: $color-default-black;

    @include vp-767 {
      padding: 0 size(6px);
      max-height: size(24.16px);
    }

    .title {
      position: relative;
      z-index: 1;
      color: $color-half-dutch-white;

      @include vp-767 {
        line-height: 100%;
      }
    }

    &.is-active {
      [data-color="wewak"] {
        @include text-wave-fill($color-wewak);
      }

      [data-color="red-orange"] {
        @include text-wave-fill($color-red-orange);
      }

      [data-color="persian-green"] {
        @include text-wave-fill($color-persian-green);
      }

      [data-color="energy-yellow"] {
        @include text-wave-fill($color-energy-yellow);
      }

      [data-color="pacific-blue"] {
        @include text-wave-fill($color-pacific-blue);
      }
    }
  }

  &__sticky-flow {
    position: absolute;
    top: 0;
    left: 50%;

    width: size(912px);
    height: size(2750px);
    transform: translateX(-49%);

    background-position-x: size(35px);

    @include vp-767 {
      top: 0;
      left: 50%;

      width: 100vw;
      height: auto;
      min-height: size(1470px);
      transform: translateX(-50%);
    }

    canvas {
      width: size(935px);
      height: size(2751px);

      @include vp-767 {
        position: relative;
        left: -15.5%;
        width: size(503px);
        height: size(1470px);
      }
    }
  }

  &__flow {
    position: relative;

    @include vp-767 {
      max-width: 100%;
      overflow: hidden;
    }
  }

  &__flow-canvas {
    width: size(900px);
    height: size(2250px);
    transform: translate3d(0, 0, 0);

    @include vp-767 {
      width: size(465px);
      height: size(944px);
    }

    canvas {
      width: size(900px);
      height: size(2250px);
      transform: translate3d(0, 0, 0);
      aspect-ratio: auto 900 / 2250;

      @include vp-767 {
        left: -12%;
      }
    }
  }

  &__grinder {
    position: relative;
    top: size(-130px);
    display: flex;
    flex-direction: column;
    align-items: center;

    @include vp-767 {
      top: size(-68px);
    }
  }


  [data-grinder-fill],
  [data-grinder-fill-mb] {
    transform: translate3d(25%, 25%, 0) scale(0.5);
  }

  [data-grinder-opacity] {
    opacity: 0;
  }

  &__top-grinder {
    position: relative;
    z-index: -1;

    svg {
      width: size(907px);
      height: size(194px);

      @include vp-767 {
        width: size(375px);
        height: size(111px);
      }
    }

    [data-grinder-fill],
    [data-grinder-fill-mb] {
      transform: translate3d(25%, 100%, 0) scale(0.5);
    }

    @include vp-767 {
      display: none;
    }

    &--mb {
      display: none;

      @include vp-767 {
        display: block;
      }
    }
  }

  &__bottom-grinder {
    position: relative;
    top: size(-167px);

    @include vp-1279 {
      top: size(-170px);
    }

    @include vp-1023 {
      top: size(-175px);
    }

    svg {
      width: size(907px);
      height: size(681px);

      @include vp-767 {
        width: size(375px);
        height: size(377px);
      }
    }

    @include vp-767 {
      display: none;
      top: size(-100px);
    }

    &--mb {
      display: none;

      @include vp-767 {
        display: block;
      }
    }
  }

  &__vent {
    position: absolute;
    bottom: size(303px);
    left: 50%;
    transform: translateX(-52%);

    width: size(457px);
    height: size(457px);

    @include vp-767 {
      bottom: size(153px);
      transform: translateX(-48%);
      width: size(249.69px);
      height: size(249.69px);
    }

    svg {
      width: size(457px);
      height: size(457px);

      @include vp-767 {
        position: static;
        width: size(249.69px);
        height: size(249.69px);
      }
    }
  }

  .sticky-block {
    @include vp-767 {
      min-height: 100vh;
      height: auto;
    }
  }

  &__text {
    position: absolute;
    left: 50%;
    bottom: size(797px);
    transform: translateX(-51%);
    z-index: 2;

    width: size(542.85px);
    height: size(80.66px);

    @include vp-767 {
      width: size(306px);
      height: size(48px);
      bottom: size(438px);
    }

    img {
      width: size(542.85px);
      height: size(80.66px);

      @include vp-767 {
        width: size(306px);
        height: size(48px);
      }
    }
  }

  &__plate {
    transform: translateZ(0);
    width: max-content;
    margin-top: size(384px);
    margin-left: size(199px);

    @include vp-767 {
      margin-top: size(316px);
      margin-left: size(18px);
    }

    &--bottom {
      margin-top: size(527px);
      margin-right: size(150px);
      margin-left: auto;

      @include vp-767 {
        margin-top: size(256px);
        margin-left: auto;
        margin-right: 0;
      }
    }
  }

  &__plate-item {
    transform: translate3d(0, 300%, 0);
    scale: 0.9;
  }

  &__blot {
    position: absolute;
    z-index: 5;

    overflow: hidden;

    &--blue {
      top: size(1195px);
      right: size(145px);

      @include vp-767 {
        top: size(720px);
        right: 0;
      }
    }

    &--red {
      top: size(1955px);
      left: size(295px);

      @include vp-767 {
        top: size(1040px);
        left: 0;
      }
    }

    @include vp-767 {
      &--1 {
        top: size(957px);
        left: 0;

        .blot {
          margin-left: size(-83px);

          width: size(320px);
          height: size(320px);
        }
      }

      &--2 {
        top: size(597px);
        right: 0;

        .blot {
          margin-right: size(-49px);

          width: size(250px);
          height: size(250px);
        }
      }
    }
  }
}
