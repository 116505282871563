/* stylelint-disable  function-no-unknown*/

.grinder {
  min-height: 400vh;
  margin-top: size(1300px);
  position: relative;

  &__wrapper {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
  }

  &__svg {
    position: relative;
    margin: 0 auto;
    width: size(904px);
    height: size(713px);
  }

  &__cup {
    position: relative;
    z-index: -1;

    svg {
      width: size(903px);
      height: size(195px);
    }
  }

  &__body {
    position: relative;
    top: size(-167px);

    svg {
      width: size(903px);
      height: size(675px);
    }
  }

  &__vent {
    position: absolute;
    z-index: 2;
    top: size(260px);
    left: 50%;
    transform: translateX(-50%);

    width: size(453px);
    height: size(453px);

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: size(453px);
      height: size(453px);
    }
  }
}
