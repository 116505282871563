.scroll {
  position: fixed;
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);
  bottom: size(96px);
  opacity: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  width:  size(80px);
  height: size(80px);
  transition: opacity $trans-default;

  @include vp-767 {
    width:  size(40px);
    height: size(40px);
  }

  &.is-active {
    opacity: 1;
  }

  &__chevron {
    position: absolute;
    width:  0.7rem * 3.5;
    height:  0.7rem * 0.9;
    opacity: 0;
    transform: scale(0.3);
    animation: move-chevron 3.6s ease-out infinite;

    @include vp-767 {
      width:  0.7rem * 3;
      height:  0.7rem * 0.6;
    }

    &:first-child {
      animation: move-chevron 3.6s ease-out 1.2s infinite;
    }

    &:nth-child(2) {
      animation: move-chevron 3.6s ease-out 2.4s infinite;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      width: 50%;
      background: $color-default-black;
    }

    &::before {
      left: 0;
      transform: skewY(30deg);
    }

    &::after {
      right: 0;
      width: 50%;
      transform: skewY(-30deg);
    }
  }
}
