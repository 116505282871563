.divider {
  position:absolute;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
  bottom: -2%;

  .btn {
    &__text {
      font-size: size(13px);
    }
  }

  &__text {
    text-align: center;
    font-family: $ff-krona;
    font-size: size(14px);
    margin-top: size(8px);
  }
}
