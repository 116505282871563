/* stylelint-disable property-no-vendor-prefix*/

@mixin retina {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}

@mixin hover {
  @media (hover: hover) {
    &:hover:not(.focus-visible) {
      @content;
    }
  }
}

@mixin focus {
  &.focus-visible:focus {
    @content;
  }
}

@mixin active {
  &.focus-visible:active {
    @content;
  }
}

@mixin hover-focus {
  @media (hover: hover) {
    &:hover:not(.focus-visible) {
      @content;
    }
  }

  &.focus-visible:focus {
    @content;
  }
}

// Desktop first

@mixin vp-1919 {
  @media (max-width: ($vp-1920 - 1px)) {
    @content;
  }
}

@mixin vp-1439 {
  @media (max-width: ($vp-1440 - 1px)) {
    @content;
  }
}

@mixin vp-1279 {
  @media (max-width: ($vp-1280 - 1px)) {
    @content;
  }
}

@mixin vp-1023 {
  @media (max-width: ($vp-1024 - 1px)) {
    @content;
  }
}

@mixin vp-767 {
  @media (max-width: ($vp-768 - 1px)) {
    @content;
  }
}

@mixin vp-374 {
  @media (max-width: ($vp-375 - 1px)) {
    @content;
  }
}

// Mobile first

// @mixin vp-375 {
//   @media (min-width: $vp-375) {
//     @content;
//   }
// }

// @mixin vp-768 {
//   @media (min-width: $vp-768) {
//     @content;
//   }
// }

// @mixin vp-1024 {
//   @media (min-width: $vp-1024) {
//     @content;
//   }
// }

// @mixin vp-1280 {
//   @media (min-width: $vp-1280) {
//     @content;
//   }
// }

// @mixin vp-1440 {
//   @media (min-width: $vp-1440) {
//     @content;
//   }
// }

// @mixin vp-1920 {
//   @media (min-width: $vp-1920) {
//     @content;
//   }
// }

// ---------------------------------

@mixin text-wave-fill($color) {
  background: linear-gradient(180deg, $color  50%, $color-half-dutch-white 50%) calc(0*100%/3) 100%/50.1% 180%;
  // radial-gradient(100% 58% at top   ,$color  100%, $color-half-dutch-white) calc(0*100%/3) 105%/50.1% 180%,
  // radial-gradient(100% 58% at bottom, $color-half-dutch-white 100%,$color) calc(3*100%/3) 105%/50.1% 180%,
  // radial-gradient(100% 58% at top   , $color  100%, $color-half-dutch-white ) calc(6*100%/3) 105%/50.1% 180%,
  // radial-gradient(100% 58% at bottom, $color-half-dutch-white 100%, $color ) calc(9*100%/3) 105%/50.1% 180%,
  // linear-gradient($color, $color) bottom/100% 0%;

  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}


// не нужно добавлять свои миксины для ресета списков, обнуления кнопок и прочие
// этим вы усложняете работу команды, проверку вашего кода и будущую поддержку проекта
