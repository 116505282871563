/* stylelint-disable  function-no-unknown*/

.title {
  margin: 0;

  font-family: $ff-neutro;
  font-weight: 700;
  font-size: size(46px);
  line-height: size(62px);

  &--red-orange-2 {
    color: $color-red-orange-2;
  }

  small {
    margin-right: size(22px);
    font-family: $ff-inter;
    font-style: normal;
    font-weight: 400;
    font-size: size(16px);
    line-height: size(20px);
    color: rgba($color-default-black, 0.5);

    @include vp-767 {
      margin-right: size(10px);
      font-size: size(9px);
      line-height: size(12px);
    }
  }

  .energy-yellow {
    color: $color-energy-yellow;
  }

  .red-orange {
    color: $color-red-orange;
  }

  @include vp-767 {
    font-size: size(26px);
    line-height: size(35px);
  }

  &--alt {
    font-size: size(40px);
    line-height: size(54px);

    @include vp-767 {
      font-size: size(24px);
      line-height: size(32px);
    }
  }

  &--sm {
    font-size: size(24px);
    line-height: size(38px);

    @include vp-767 {
      font-size: size(20px);
      line-height: size(28px);
    }
  }

  &--sm-alt {
    font-size: size(24px);
    line-height: size(38px);

    @include vp-767 {
      font-size: size(24px);
      line-height: size(38px);
    }
  }

  &--xs {
    font-size: size(24px);
    line-height: size(38px);

    @include vp-767 {
      font-size: size(16px);
      line-height: size(20px);
    }
  }

  &__outer-stroke {
    text-shadow: size(3px) 0 0 $color-default-black,
      size(-3px) 0 0 $color-default-black,
      0 size(-3px) 0 $color-default-black,
      0 size(3px) 0 $color-default-black,
      size(-3px) size(-3px) 0 $color-default-black,
      size(3px) size(3px) 0 $color-default-black,
      size(3px) size(-3px) 0 $color-default-black,
      size(-3px) size(3px) 0 $color-default-black,
      size(3px) size(-2px) 0 $color-default-black;


    @include vp-767 {
      text-shadow: size(2px) 0 0 $color-default-black,
        size(-2px) 0 0 $color-default-black,
        0 size(-2px) 0 $color-default-black,
        0 size(2px) 0 $color-default-black,
        size(-2px) size(-2px) 0 $color-default-black,
        size(2px) size(2px) 0 $color-default-black,
        size(2px) size(-2px) 0 $color-default-black,
        size(-2px) size(2px) 0 $color-default-black;
    }
  }

  &__outer-stroke-last-letter-o {
    text-shadow: size(3px) 0 0 $color-default-black,
      size(-3px) 0 0 $color-default-black,
      0 size(-3px) 0 $color-default-black,
      0 size(3px) 0 $color-default-black,
      size(-3px) size(-3px) 0 $color-default-black,
      size(3px) size(3px) 0 $color-default-black,
      size(3px) size(-3px) 0 $color-default-black,
      size(-3px) size(3px) 0 $color-default-black,
      size(3px) size(-2px) 0 $color-default-black,
      size(4px) size(-2px) 0 $color-default-black,
      size(4px) size(1px) 0 $color-default-black;

    @include vp-767 {
      text-shadow: size(2px) 0 0 $color-default-black,
        size(-2px) 0 0 $color-default-black,
        0 size(-2px) 0 $color-default-black,
        0 size(2px) 0 $color-default-black,
        size(-2px) size(-2px) 0 $color-default-black,
        size(2px) size(2px) 0 $color-default-black,
        size(2px) size(-2px) 0 $color-default-black,
        size(-2px) size(2px) 0 $color-default-black,
        size(3px) size(1px) 0 $color-default-black,
        size(3px) size(-1px) 0 $color-default-black;
    }
  }

  &__outer-stroke-first-letter-o {
    text-shadow: size(3px) 0 0 $color-default-black,
      size(-3px) 0 0 $color-default-black,
      0 size(-3px) 0 $color-default-black,
      0 size(3px) 0 $color-default-black,
      size(-3px) size(-3px) 0 $color-default-black,
      size(3px) size(3px) 0 $color-default-black,
      size(3px) size(-3px) 0 $color-default-black,
      size(-3px) size(3px) 0 $color-default-black,
      size(3px) size(-2px) 0 $color-default-black,
      size(-4px) size(-1px) 0 $color-default-black,
      size(-4px) size(1px) 0 $color-default-black;

    @include vp-767 {
      text-shadow: size(2px) 0 0 $color-default-black,
        size(-2px) 0 0 $color-default-black,
        0 size(-2px) 0 $color-default-black,
        0 size(2px) 0 $color-default-black,
        size(-2px) size(-2px) 0 $color-default-black,
        size(2px) size(2px) 0 $color-default-black,
        size(2px) size(-2px) 0 $color-default-black,
        size(-2px) size(2px) 0 $color-default-black,
        size(-3px) size(-1px) 0 $color-default-black,
        size(-3px) size(1px) 0 $color-default-black;
    }
  }

  &__outer-stroke-special {

    -webkit-text-stroke-width: size(3.2px);
    -webkit-text-stroke-color: $color-default-black;

    transform: scale(1.08);
    display: block;
    letter-spacing: size(-3px);

    @include vp-767 {
      -webkit-text-stroke-width: size(2px);
      letter-spacing: size(-1.9px);
    }
  }

  &__nowrap {
    white-space: nowrap;
  }
}
