// @font-face {
//   font-style: normal;
//   font-weight: 400;
//   font-family: "Rouble";

//   font-display: swap;
//   src:
//     url("../fonts/rouble.woff2") format("woff2");
// }

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Inter";

  font-display: swap;
  src:
    url("../fonts/inter-v12-cyrillic_latin-regular.woff2") format("woff2"),
    url("../fonts/inter-v12-cyrillic_latin-regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "Inter";

  font-display: swap;
  src:
    url("../fonts/inter-v12-cyrillic_latin-500.woff2") format("woff2"),
    url("../fonts/inter-v12-cyrillic_latin-500.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Krona One";

  font-display: swap;
  src:
    url("../fonts/krona-one-v14-latin-regular.woff2") format("woff2"),
    url("../fonts/krona-one-v14-latin-regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "Neutro";

  font-display: swap;
  src:
    url("../fonts/neutro-700.woff2") format("woff2"),
    url("../fonts/neutro-700.woff") format("woff");
}

//----------ПРОВЕРКА
// @font-face {
//   font-style: normal;
//   font-weight: 700;
//   font-family: "NeutroOld";

//   font-display: swap;
//   src:
//     url("../fonts/___neutro-bold.woff2") format("woff2"),
//     url("../fonts/___neutro-bold.woff") format("woff");
// }
