/* stylelint-disable  function-no-unknown*/

.btn {
  display: inline-flex;
  padding: 10px 20px;

  color: $color-default-white;

  background-color: $color-energy-yellow;
  border: none;
  cursor: pointer;
  pointer-events: all;

  transition: opacity $trans-default;

  &--red {
    background-color: $color-red-orange;
  }

  &:disabled {
    opacity: 0.5;

    pointer-events: none;
  }
}

.btn--main {
  position: relative;

  padding: 0;
  background-color: $color-transparent;
  transform: translateZ(0);

  .btn__text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: size(7px) size(32px);
    font-weight: 700;
    font-size: size(16px);
    line-height: size(24px);
    text-align: center;
    text-transform: uppercase;
    color: $color-default-black;
    background-color: $color-energy-yellow;
    border: size(3px) solid $color-default-black;

    @include vp-767 {
      padding: size(3px) size(23px);
      font-size: size(14px);
      line-height: size(24px);
    }
  }

  .btn__shadow {
    position: absolute;
    top: size(4px);
    left: size(-4px);
    right: size(4px);
    bottom: size(-4px);
    z-index: -1;

    display: block;
    background-color: $color-default-black;
    transform: translateZ(0);
  }

  @include hover-focus {
    // transform: scale(1.1);

    // .btn__shadow {
    //   transform: translate3d(size(-4px), size(4px), 0);
    // }
  }
}

.btn--submit {
  border-radius: 0;
  background-color: $color-wewak;
  border: size(3px) solid $color-default-black;
  font-size: size(16px);
  line-height: size(24px);
  padding: size(13px) size(8px);
  color: $color-default-black;
  width: 100%;
  display: flex;
  justify-content: center;
  transition: opacity $trans-600;

  @include hover-focus {
    opacity: 0.6;
  }

  @include vp-767 {
    padding: size(5px) size(8px);
  }
}
