
.more-tools {
  position: relative;
  z-index: 1;

  min-height: size(500px);
  margin-bottom: 6.2vh;
  margin-top: size(323px);


  @media (min-width: ($vp-768 - 1)) and (max-width: $vp-1024) and (pointer: coarse) {
    @media (orientation: portrait) {
      margin-top: size(-30px);
    }

    @media (orientation: landscape) {
      margin-top: size(-150px);
    }
  }

  @include vp-767 {
    margin-top: size(-424px);
    // margin-top: -61vh;

    &__sticky {
      height: size(700px);
      width: 100%;
    }

    &__image-mob {
      margin-top: size(70px);
      width: 100%;
      height: 100%;

      img {
        object-position: 41%;
        object-fit: cover;
        width: auto;
        height: 100%;
      }
    }
  }

  &__sticky-container {
    position: relative;
    min-height: calc(#{size(700px) * 3});

    // @media (min-width: ($vp-768 - 1)) and (max-width: $vp-1024) and (pointer: coarse) {
    //   @media (orientation: portrait) {
    //     min-height: 250vh;
    //   }
    // }

    @include vp-767 {
      // margin-top: -40vh;
      min-height: auto;
    }
  }

  &__decorate-block {
    position: relative;

    @include vp-767 {
      margin-bottom: size(33px);
    }
  }

  &__text-block {
    display: flex;
    justify-content: flex-end;
    position: relative;

    @include vp-767 {
      display: block;
      justify-content: unset;
      overflow: hidden;
      width: 100vw;
      padding-top: size(127px);
      padding-bottom: size(109px);
    }
  }

  &__text-block-container {
    width: 38.7%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include vp-767 {
      width: unset;
      padding-top: size(20px);
      padding-bottom: size(40px);
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: size(-92px);
        left: 50%;
        bottom: size(-52px);
        z-index: -1;

        min-width: calc(100% + #{size(58px)});
        padding-bottom: calc(100% + #{size(58px)});
        background-color: $color-energy-yellow;
        border: size(2px) solid $color-default-black;
        border-radius: size(228px);
        transform: translate3d(-50%, 0, 0);
      }
    }
  }

  &__slogan {
    text-align: center;
    font-size: size(24px);
    line-height: size(38px);
    margin: 0 0 size(32px) 0;
    font-weight: 700;
    max-width: size(400px);

    @include vp-767 {
      font-size: size(20px);
      line-height: size(28px);
      margin: 0 0 size(23px) 0;
      max-width: size(340px);
    }
  }

  &__text {
    font-family: $ff-inter;
    font-weight: 400;
    font-size: size(16px);
    line-height: size(24px);
    text-align: center;
    color: $color-default-black;
    opacity: 0.5;
    max-width: size(400px);
    margin: 0;

    @include vp-767 {
      max-width: size(343px);
    }
  }

  &__img-block {
    position: relative;
    height: 115vh;
    // overflow: hidden;
    margin-top: size(64px);

    @include vp-767 {
      display: flex;
      margin-top: size(-130px);
      height: 100%;
    }
  }

  &__img-element {
    position: absolute;
    will-change: transform, opacity;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__img-element-1 {
    width: size-vh(302);
    height: size-vh(456);
    top: size-vh(233);
    left: 50%;
    transform: translateX(2%);

    @include vp-767 {
      width: size-vh(208);
      height: size-vh(314);
      top: size-vh(283);
      transform: translate3d(1%, 0, 0);
    }
  }

  &__img-element-2 {
    width: size-vh(224);
    height: size-vh(435);
    top: size-vh(163);
    left: 50%;
    transform: translateX(-177%);

    @include vp-767 {
      width: size-vh(155);
      height: size-vh(300);
      top: size-vh(321);
      transform: translate3d(-156%, 0, 0);
    }
  }

  &__img-element-3 {
    width: size-vh(449);
    height: size-vh(554);
    top: size-vh(96);
    left: 50%;
    transform: translateX(-57%);

    @media (min-width: ($vp-768 - 1)) and (max-width: $vp-1024) {
      @media (orientation: portrait) {
        top: size-vh(150);
      }
    }

    @include vp-767 {
      width: size-vh(277);
      height: size-vh(344);
      top: size-vh(340);
      transform: translate3d(-55%, 0, 0);
    }
  }

  &__img-element-4 {
    width: size-vh(508);
    height: size-vh(390);
    top: size-vh(611);
    left: 50%;
    transform: translateX(-90%);
  }

  &__img-element-5 {
    width: size-vh(420);
    height: size-vh(386);
    top: size-vh(630);
    left: 52%;
    transform: translateX(-39%);

    @include vp-767 {
      width: size-vh(289);
      height: size-vh(266);
      top: size-vh(719);
      transform: translate3d(-49%, 0, 0);
    }
  }

  &__img-element-6 {
    width: size-vh(628);
    height: size-vh(628);
    top: size-vh(-129);
    left: 50%;
    transform: translateX(-22%);

    @include vp-767 {
      width: size-vh(433);
      height: size-vh(433);
      top: size-vh(63);
      transform: translate3d(-32%, 0, 0);

      z-index: 2;
    }
  }


  &__img-element-7 {
    width: size-vh(507);
    height: size-vh(355);
    top: size-vh(367);
    left: 50%;
    transform: translateX(-116%);

    @include vp-767 {
      width: size-vh(350);
      height: size-vh(245);
      top: size-vh(534);
      left: 70%;
      transform: translate3d(-97%, 0, 0);
    }
  }

  &__img-element-8 {
    width: size-vh(283);
    height: size-vh(190);
    top: size-vh(255);
    left: 50%;
    transform: translateX(63%);

    @include vp-767 {
      width: size-vh(195);
      height: size-vh(131);
      top: size-vh(407);
      transform: translate3d(1%, 0, 0);

      z-index: 1;
    }
  }

  &__img-element-9 {
    width: size-vh(396);
    height: size-vh(318);
    top: size-vh(120);
    left: 50%;
    transform: translateX(-170%);

    @include vp-767 {
      width: size-vh(273);
      height: size-vh(219);
      top: size-vh(34);
      transform: translate3d(-79%, 0, 0);

      z-index: 1;
    }
  }

  &__img-element-10 {
    width: size-vh(539);
    height: size-vh(382);
    top: size-vh(138);
    left: 50%;
    transform: translateX(30%);

    @include vp-767 {
      width: size-vh(372);
      height: size-vh(263);
      top: size-vh(424);
      transform: translate3d(13%, 0, 0);
    }
  }

  &__img-element-11 {
    width: size-vh(340);
    height: size-vh(418);
    top: size-vh(476);
    left: 50%;
    transform: translateX(51%);

    @include vp-767 {
      width: size-vh(234);
      height: size-vh(288);
      top: size-vh(85);
      transform: translate3d(22%, 0, 0);

      z-index: 1;
    }
  }

  &__img-element-12 {
    width: size-vh(321);
    height: size-vh(465);
    top: size-vh(415);
    left: 50%;
    transform: translateX(127%);

    @include vp-767 {
      width: size-vh(222);
      height: size-vh(321);
      top: size-vh(575);
      transform: translate3d(19%, 0, 0);
    }
  }

  &__img-element-13 {
    width: size-vh(311);
    height: size-vh(463);
    top: size-vh(302);
    left: 50%;
    transform: translateX(-238%);

    @include vp-767 {
      width: size-vh(214);
      height: size-vh(319);
      top: size-vh(156);
      transform: translate3d(-109%, 0, 0);

      z-index: 2;
    }
  }

  &__img-element-14 {
    width: size-vh(252);
    height: size-vh(321);
    top: size-vh(387);
    left: 50%;
    transform: translateX(126%);

    @include vp-767 {
      width: size-vh(251);
      height: size-vh(321);
      top: size-vh(686);
      transform: translate3d(-25%, 0, 0);
    }
  }

  &__img-element-15 {
    width: size-vh(337);
    height: size-vh(368);
    top: 0;
    left: 50%;
    transform: translateX(-74%);

    @media (min-width: ($vp-768 - 1)) and (max-width: $vp-1024) {
      @media (orientation: portrait) {
        top: size-vh(-20);
        left: 54%;
      }
    }

    @include vp-767 {
      width: size-vh(232);
      height: size-vh(253);
      top: size-vh(152);
      transform: translate3d(-71%, 0, 0);
    }
  }

  &__img-element-16 {
    width: size-vh(316);
    height: size-vh(261);
    top: size-vh(601);
    left: 50%;
    transform: translateX(-188%);

    @include vp-767 {
      width: size-vh(218);
      height: size-vh(180);
      top: size-vh(701);
      transform: translate3d(-106%, 0, 0);
    }
  }

  &__img-element-17 {
    width: size-vh(158);
    height: size-vh(158);
    top: size-vh(47);
    left: 50%;
    transform: translateX(5%);

    @include vp-767 {
      width: size-vh(136);
      height: size-vh(136);
      top: size-vh(58);
      transform: translate3d(-22%, 0, 0);

      z-index: 3;
    }
  }

  &__img-element-18 {
    width: size-vh(281);
    height: size-vh(184);
    top: size-vh(555);
    left: 50%;
    transform: translateX(-57%);

    @include vp-767 {
      width: size-vh(194);
      height: size-vh(127);
      top: size-vh(385);
      transform: translate3d(-141%, 0, 0);
    }
  }

  &__canvases {

    @include vp-767 {
      top: size(-202px);
      left: -58%;
    }
  }

  &__flow-canvas {
    position: absolute;
    z-index: -1;

    top: size(-222px);

    width: size(1440px);
    height: size(2678px);

    @include vp-767 {
      width: size(792px);
      height: size(1473px);
    }

    img {
      width: 100%;
      height: 100%;
    }

    // canvas {
    //   width: size(1440px);
    //   height: size(2678px);
    // }
  }
}
