/* stylelint-disable  function-no-unknown*/

.preloader-block {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  min-height: calc(100 * var(--vh, 1vh));
  max-height: calc(100 * var(--vh, 1vh));
  z-index: 100000;

  &__wrapper {
    width: 100%;

    min-height: calc(100 * var(--vh, 1vh));

    max-height: calc(100 * var(--vh, 1vh));
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &__main-area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: calc(50 * var(--vh, 1vh));
  }

  &__top-area {
    width: 100%;
    min-height: size(104px);
  }

  &__logo-animate {
    width: max-content;
    max-width: 100%;
    height: size(104px);
    margin-left: size(3px);
    margin-right: auto;
    opacity: 0.1;
    display: flex;

    @include vp-767 {
      height: size(40px);
      width: 98%;
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
      flex-grow: 1;
    }
  }

  &__info-area {
    padding-top: size(140px);
    transition: opacity ease 0.3s;

    @include vp-767 {
      padding-top: size(20px);
    }
  }

  &__slogan {
    font-size: size(24px);
    line-height: size(32px);
    margin: 0;
    text-align: center;
    margin-bottom: size(44px);
    text-transform: uppercase;

    @include vp-767 {
      font-size: size(18px);
      line-height: size(24px);
      margin-bottom: size(30px);

      br {
        display: none;
      }
    }
  }

  &__progress-bar-out {
    width: size(670px);
    height: size(19px);
    position: relative;

    background: $color-energy-yellow;
    border: size(3px) solid $color-default-black;
    overflow: hidden;

    @include vp-767 {
      width: size(330px);
      margin: 0 auto;
    }
  }

  &__progress-bar-in {
    position: absolute;
    left: 0;
    top: 0;
    height: 101%;
    width: 101%;
    background-color: $color-default-black;
    transform: translateX(-100%);
    will-change: transform;
  }

  &__progress-bar-in-pseudo {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 25%;

    animation-duration: 2.1s;
    animation-name: loadline-pseudo;
    animation-direction: alternate;
    z-index: -1;
    opacity: 0;
  }

  @keyframes loadline-pseudo {
    from {
      width: 0;
    }

    to {
      width: 25%;
    }
  }

  &__bottom-area {
    transition: opacity ease 0.3s;
    width: 100%;
  }

  &__animate-path {
    position: relative;
    width: 100%;
    min-height: calc(40 * var(--vh, 1vh));
  }

  &__img-base {
    position: absolute;
    bottom: size(-2px);
    height: size(30px);
    width: 100%;

    @include vp-767 {
      height: size(7.8px);
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__lottie {
    position: absolute;
    bottom: size(-61px);
    z-index: 100;

    width: size(500px);
    height: size(371px);
    transform: translate3d(-100%, 0, 0);
    will-change: transform;

    @include vp-767 {
      bottom: size(-50px);

      width: size(336px);
      height: size(250px);
      transform: translate3d(-100%, 0, 0);
    }

    canvas {
      width: 100%;
      height: 100%;
    }
  }
}

.preloader-block.is-hidden {
  pointer-events: none;

  .preloader-block__info-area,
  .preloader-block__bottom-area {
    opacity: 0;
  }
}


.wrapper.has-loader {
  .intro__tagline {
    opacity: 0;
    transform: translate3d(0, 10%, 0);
    transition: opacity ease 0.4s, transform ease 0.4s;
  }

  .intro__title-wrapper,
  .intro__plate {
    opacity: 0;
    transition: opacity ease 0.4s;
  }

  .intro__sticky-flow {
    opacity: 0;
    transition: opacity ease 0.01s;
    transition-delay: 0.6s;
  }
}

// анимация появления
.wrapper.has-loader.is-loaded {
  .intro__tagline {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  .intro__title-wrapper {
    opacity: 1;
  }

  .intro__sticky-flow {
    opacity: 1;
  }

  .intro__plate {
    opacity: 1;
  }
}
