/* stylelint-disable  function-no-unknown*/

.footer {
  padding: size(87px) 0 size(32px);

  background-color: $color-transparent;

  @include vp-767 {
    padding: size(6px) 0 size(6px);
  }

  &__img {
    width: 100%;
    height: size(104px);

    @include vp-767 {
      height: size(30px);
    }

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
}
