/* stylelint-disable  function-no-unknown*/

.container {
  width: 100%;
  max-width: unset;
  margin: 0 auto;
  padding: 0 size(64px);

  @include vp-1439 {
    padding: 0 size(64px);
  }

  @include vp-1023 {
    padding: 0 size(64px);
  }

  @include vp-767 {
    padding: 0 size(16px);
  }
}

.container--footer {
  padding: 0 size(8px);

  @include vp-1439 {
    padding: 0 size(8px);
  }

  @include vp-1023 {
    padding: 0 size(8px);
  }

  @include vp-767 {
    padding: 0 size(16px);
  }
}
