/* stylelint-disable  function-no-unknown*/

.text {
  margin: 0;
  font-family: $ff-inter;
  font-style: normal;
  font-weight: 400;
  font-size: size(16px);
  line-height: size(24px);
  color: rgba($color-default-black, 0.5);

  p {
    margin: 0;
  }

  & + &,
  p + p {
    margin-top: size(23px);
  }

  &--lg {
    font-size: size(24px);
    line-height: size(36px);
    color: $color-default-black;

    @include vp-767 {
      font-size: size(16px);
      line-height: size(24px);
    }
  }

  &--md {
    font-size: size(14px);
    line-height: size(20px);
  }

  &--sm {
    @include vp-767 {
      font-size: size(12px);
      line-height: size(24px);
    }
  }
}
