/* stylelint-disable  function-no-unknown*/

.marketing {
  position: relative;

  padding: 0 0 size(503px);
  overflow: hidden;
  margin-top: size(-1150px);
  transform: translateZ(0);
  will-change: transform;

  @media (max-width: $vp-1024) {
    @media (orientation: portrait) {
      margin-top: size(-400px);
    }
  }

  @include vp-767 {
    padding: 0 0 size(200px);
    margin-top: size(-1270px);
  }

  &__content {
    position: relative;
    z-index: 1;

    max-width: size(740px);
    margin: 0 auto size(55px);
    text-align: center;

    .title {
      margin: size(20px) 0 size(16px);
    }

    @include vp-767 {
      margin-bottom: size(40px);

      .title {
        margin: size(12px) 0 size(15px);
      }
    }
  }

  &__marquee {
    position: relative;
    z-index: 1;

    display: flex;
    width: calc(100% + #{size(40px)});
    margin: 0 size(-20px);
    padding: size(4px) size(92px);
    background-color: $color-energy-yellow;
    border: size(3px) solid $color-default-black;
    white-space: nowrap;
    transform: rotate(-2.04deg);
    pointer-events: none;
    overflow: hidden;
    will-change: transform;

    @include vp-767 {
      padding: size(1px) size(28px);
    }
  }

  &__marquee-part {
    flex-shrink: 0;
    display: flex;
    white-space: nowrap;
    transform: translateZ(0);
    will-change: transform;

    p {
      flex-shrink: 0;
      margin: 0;
      padding-right: size(88px);
      will-change: transform;
    }

    @include vp-767 {
      font-size: size(23px);
      line-height: size(21px);

      p {
        padding-right: size(44px);
      }
    }
  }

  &__fish {
    position: absolute;
    right: 27.7%;
    bottom: size(404px);

    will-change: transform, opacity;

    @include vp-1023 {
      bottom: size(370px);
    }

    @include vp-767 {
      right: 72%;
      bottom: size(120px);
    }
  }

  &__fish-icon {
    width: size(177px);
    height: size(182px);

    svg {
      width: 100%;
      height: 100%;
    }

    @include vp-767 {
      width: size(116px);
      height: auto;
    }
  }

  &__speach-bubble {
    position: absolute;
    right: -143%;
    bottom: -12%;

    width: size(242px);
    padding: size(14px);

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;

      width: auto;
      min-height: size(97px);
      height: 100%;
      transform: translate(-50%, -55%);
    }

    @include vp-767 {
      right: -215%;
      bottom: -26%;

      svg {
        min-height: size(96px);
      }
    }
  }

  &__speach-text {
    margin: 0;
    font-family: $ff-inter;
    font-style: normal;
    font-weight: 500;
    font-size: size(12px);
    line-height: size(15px);
    text-align: center;
  }
}
