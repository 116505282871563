/* stylelint-disable  function-no-unknown*/

.slider {
  position: relative;
  z-index: 9;
  overflow: hidden;

  &__container {
    height: 100%;
  }

  &__slide {
    transform: translateZ(0);
  }

  &__img {
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      user-select: none;
    }
  }

  &--development {
    .slider__slide {
      position: relative;
      width: size(604px);
      margin-right: size(31px);
      transition: opacity $trans-default;

      &:last-child {
        margin-right: 0;
      }

      &.swiper-slide-prev {
        opacity: 0;
      }

      @include vp-767 {
        width: size(310px);
        margin-right: size(21px);
      }
    }

    .slider__img {
      width: 100%;
      height: size(335px);
      margin-bottom: size(17px);
      background-color: $color-energy-yellow;
      border: size(3px) solid $color-default-black;
      box-shadow: size(-4px) size(4px) 0 0 $color-default-black;

      @include vp-767 {
        height: size(172px);
        margin-bottom: size(14px);
        box-shadow: size(-6px) size(4px) 0 $color-default-black;
      }
    }

    .slider__logo {
      position: absolute;
      top: size(325px);
      left: 0;

      display: flex;
      justify-content: center;
      align-items: center;
      width: size(90px);
      height: size(90px);
      padding: size(12px);
      background-color: $color-energy-yellow;
      border: size(3px) solid $color-default-black;
      border-radius: 50%;

      svg {
        width: 100%;
        height: auto;
      }

      @include vp-767 {
        top: size(178px);
        left: size(1px);
        width: size(59px);
        height: size(59px);
        padding: size(8px);
        border-width: size(3px);
      }
    }

    .slider__block {
      min-height: size(64px);
      padding-left: size(105px);

      @include vp-767 {
        min-height: size(60px);
        margin-right: size(-5px);
        padding-left: size(72px);
      }
    }
  }

  &--analytics {
    padding-bottom: size(4px);

    .slider__slide {
      display: flex;
      width: size(762px);
      margin-right: size(36px);
      padding: size(21px);
      background-color: $color-energy-yellow;
      border: size(3px) solid $color-default-black;
      box-shadow: size(-4px) size(4px) 0 0 $color-default-black;
      transition: opacity $trans-default;

      &:last-child {
        margin-right: 0;
      }

      &.swiper-slide-prev {
        opacity: 0;
      }

      @include vp-767 {
        display: block;
        width: size(297px);
        margin-right: size(16px);
        padding: size(22px);
      }
    }

    .slider__block {
      margin-right: size(44px);

      .title {
        margin-bottom: size(15px);
      }

      @include vp-767 {
        margin-right: 0;
        margin-bottom: size(33px);
      }
    }

    .slider__img {
      flex-shrink: 0;
      width: size(424px);
      height: size(361px);

      @include vp-767 {
        width: 100%;
        height: size(212px);
      }
    }
  }
}
