/* stylelint-disable  function-no-unknown*/

.game-plan {
  position: relative;
  display: flex;
  justify-content: center;

  padding-top: size(351px);

  @include vp-767 {
    padding-top: size(251px);
  }

  .title {
    text-align: center;
    max-width: size(932px);

    @include vp-767 {
      max-width: size(358px);
    }

    span {
      color: $color-energy-yellow;
      -webkit-text-stroke: size(3px) $color-default-black;

      @include vp-767 {
        -webkit-text-stroke: size(2px) $color-default-black;
      }
    }
  }

  &__fish {
    position: absolute;
    left: 51.9%;
    bottom: size(-180px);

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 49%;
    height: size(200px);
    padding-left: size(7px);

    overflow: hidden;

    @include vp-767 {
      left: 44.2%;
      bottom: size(-245px);

      width: 54%;
      padding-left: size(66px);
    }
  }

  &__fish-icon {
    width: size(98px);
    height: size(86px);
    will-change: transform, opacity;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__speach-bubble {
    position: absolute;
    left: size(111px);
    bottom: size(104px);

    width: size(178px);
    padding: size(14px);

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;

      min-width: size(178px);
      width: auto;
      min-height: size(72px);
      height: 100%;
      transform: translate(-50%, -47%);
    }

    @include vp-767 {
      left: 0;
      bottom: size(116px);

      width: size(178px);

      svg {
        width: size(178px);
        min-height: size(78px);
        transform: translate(-50%, -43%);
      }
    }
  }

  &__speach-icon {
    &--mob {
      display: none;
    }

    @include vp-767 {
      display: none;

      &--mob {
        display: block;
      }
    }
  }

  &__speach-text {
    margin: 0;
    font-family: $ff-inter;
    font-style: normal;
    font-weight: 500;
    font-size: size(12px);
    line-height: size(15px);
    text-align: center;
  }
}
