/* stylelint-disable  function-no-unknown, declaration-no-important */

.custom-input,
.custom-textarea {
  position: relative;

  display: flex;
  width: 530px;
  padding: 14px 0 28px;

  @media screen and (max-width: 767px) {
    padding: 4px 0 48px;
    width: 100%;
  }

  label {
    display: flex;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 767px) {
      align-items: flex-start;
      flex-direction: column;
    }

    input,
    textarea {
      flex-grow: 1;
      max-width: 300px;
      min-height: 48px;
      margin-left: auto;
      padding: 10px;

      border: 2px solid rgba(#000000, 0.2);
      border-radius: 5px;
      outline: none;

      transition: border 0.3s ease;

      @media screen and (max-width: 767px) {
        max-width: unset;
        width: 100%;
      }
    }
  }

  &__label {
    @media screen and (max-width: 767px) {
      margin: 0 0 18px 0;
    }
  }

  &__error {
    position: absolute;
    right: 0;
    bottom: 35px;

    color: #ff1553;

    opacity: 0;

    transition: opacity 0.3s ease;

    @media screen and (max-width: 767px) {
      bottom: 20px;
    }
  }

  &.is-invalid {
    input,
    textarea {
      border: 2px solid #ff1553;

      &:focus,
      &:hover {
        border: 2px solid rgba(#ff1553, 0.5);
      }
    }
  }

  &.is-valid {
    input {
      border: 2px solid #22b259;

      &:focus,
      &:hover {
        border: 2px solid rgba(#22b259, 0.5);
      }
    }
  }

  .input-message {
    position: absolute;
    right: 0;
    bottom: -5px;

    &.is-invalid {
      color: #ff0000;
    }

    &.is-valid {
      color: #15ab07;
    }
  }
}

.custom-input--feedback.custom-input,
.custom-textarea--feedback.custom-textarea {
  padding: 0;
  width: 100%;
  margin-bottom: size(12px);

  .input-message.is-invalid {
    font-family: $ff-inter;
    font-weight: 400;
    font-size: size(16px);
    line-height: size(24px);
    bottom: size(9px);
    right: size(15px);
  }
}

.custom-input--feedback.custom-input.is-valid input,
.custom-input--feedback.custom-input.is-invalid input {
  border: size(3px) solid $color-default-black;
}

.custom-input--feedback.custom-input.is-invalid.is-invalid-add input {
  transition: background-color $trans-1000;
  background-color: rgba($color-red-orange-2, 0.5);
}

.custom-input--feedback.custom-input.is-invalid input {
  transition: background-color $trans-1000;
  background-color: $color-transparent;
}

.custom-input--feedback,
.custom-textarea--feedback {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px $color-energy-yellow inset !important;
  }

  .input-message {
    &.is-invalid {
      color: $color-red-orange-2;
    }
  }

  label textarea,
  label input {
    width: 100%;
    margin-left: unset;
    max-width: unset;
    border-radius: 0;
    border: size(3px) solid $color-default-black;
    min-height: unset;
    height: size(42px);
    padding: size(9px) size(15px);
    background-color: $color-transparent;
    font-family: $ff-inter;
    font-weight: 400;
    font-size: size(16px);
    line-height: size(24px);
    opacity: 1;
    color: $color-default-black;
    transition: opacity $trans-600;
    cursor: pointer;
    transform: translateZ(0);

    @include vp-767 {
      padding: size(9px) size(12px);
    }

    @include hover-focus {
      &:hover {
        opacity: 0.6;
      }

      &:focus {
        border: size(3px) solid rgba($color-default-black, 0.6);
      }
    }
  }

  label textarea {
    height: unset;
    min-height: size(140px);
  }

  label input::placeholder,
  textarea::placeholder {
    font-family: $ff-inter;
    font-weight: 400;
    font-size: size(16px);
    line-height: size(24px);
    opacity: 0.5;
    color: $color-default-black;

    @include vp-767 {
      font-size: size(12px);
    }
  }
}
