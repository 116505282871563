/* stylelint-disable  function-no-unknown*/

.splash {
  position: relative;
  display: flex;
  justify-content: center;

  height: 500vh;
  margin-top: size(1200px);
  margin-bottom: size(231px);

  @media (max-width: $vp-1024) {
    @media (orientation: portrait) {
      height: 300vh;
    }
  }

  @include vp-767 {
    @media (orientation: portrait) {
      height: 500vh;
    }
  }

  @media (pointer: coarse) and (orientation: landscape) {
    margin-top: size(1300px);
  }

  @include vp-1023 {
    margin-top: size(1000px);
  }

  @include vp-767 {
    margin-top: size(375px);
    margin-bottom: size(39px);
  }

  .sticky-block {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    height: max-content;

    @media (min-width: $vp-1024) and (max-width: 1366px) and (pointer: coarse) and (orientation: landscape) {
      top: 20vh;
    }

    @media (max-width: $vp-1024) and (pointer: coarse) {
      top: 25vh;
    }


    @include vp-1023 {
      top: 25vh;
    }

    @include vp-1023 {
      @media (orientation: landscape) {
        top: 0;
      }
    }

    @include vp-1023 {
      align-items: start;
    }

    @include vp-767 {
      top: -20vh;
    }
  }

  &__straight-flow {
    position: absolute;
    top: size(-2041px);
    z-index: 2;


    width: size(1484px);
    height: size(2501px);

    @media (min-width: 1024px) and (max-width: 1366px) and (pointer: coarse) and (orientation: landscape) {
      top: size(-2150px);
    }

    @include vp-767 {
      width: size(875px);
      height: size(1485px);
      top: size(-900px);
      display: flex;
      justify-content: center;
    }

    canvas {
      width: size(1484px);
      height: size(2501px);
    }
  }

  &__brushstroke {
    position: absolute;
    z-index: 6;
    top: size(-100px);
    left: 0;

    max-width: 100%;
    overflow: hidden;

    @include vp-1439 {
      top: size(-40px);
    }

    @include vp-767 {
      top: 20vh;
    }
  }

  &__brushstroke-canvas {
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    width: size(2309px);
    height: size(856px);

    @include vp-767 {
      width: size(1016.5px);
      height: size(851.5px);
    }

    canvas {
      transform: translateZ(0);

      width: 100%;
      height: 100%;
    }
  }

  .title {
    text-align: center;
    max-width: size(1120px);

    @include vp-767 {
      max-width: size(372px);
    }
  }

  &__blot {
    position: absolute;
    z-index: 2;

    &--black {
      left: size(-87px);
      top: size(-717px);

      @include vp-767 {
        top: size(-327px);
        left: size(-40px);
      }
    }

    &--yellow {
      right: size(148px);
      top: size(-214px);

      @include vp-767 {
        right: size(-50px);
        top: size(-120px);
      }
    }

    &--blue {
      top: size(-605px);
      right: size(19px);

      @include vp-767 {
        right: size(-40px);
        top: size(-270px);
      }
    }

    &--red {
      left: size(3px);
      top: size(-570px);

      @include vp-767 {
        top: size(-200px);
        left: size(-50px);
      }
    }
  }

  &__canvas-text {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    transform: translateZ(0);
    opacity: 0;
    scale: 0.8;

    will-change: scale, opacity;

    @include vp-767 {
      max-height: 100vh;
    }

    .title {
      margin-bottom: size(46px);
      color: $color-half-dutch-white;
      transform: translateZ(0);

      color: $color-energy-yellow;
      -webkit-text-stroke: size(3px) $color-default-black;

      @include vp-767 {
        margin-bottom: size(24px);
        -webkit-text-stroke: size(2px) $color-default-black;
      }
    }

    .text {
      font-family: $ff-neutro;
      text-align: center;
      font-weight: 700;
      max-width: size(777px);
      color: $color-half-dutch-white;
      transform: translateZ(0);

      @include vp-767 {
        max-width: size(331px);
        font-size: size(16px);
        line-height: size(24px);
      }
    }
  }

  &__title {
    position: relative;
    margin-top: size(375px);

    @media (min-width: 1024px) and (max-width: 1366px) and (pointer: coarse) and (orientation: landscape) {
      margin-top: size(275px);
    }

    @include vp-767 {
      margin-top: size(500px);
    }
  }

  &__fish {
    position: absolute;
    right: size(-90px);
    top: size(136px);

    @include vp-767 {
      top: size(146px);
      right: size(20px);
    }
  }

  &__speech-bubble {
    position: absolute;
    left: size(-162px);
    top: size(24px);

    width: size(175px);
    height: size(68.5px);

    @include vp-767 {
      top: size(38px);
      left: size(-194px);
      width: size(197.34px);
      height: size(62px);
    }

    svg {
      position: absolute;
      top: size(-23px);
      z-index: -1;

      width: size(175px);
      height: size(68.5px);

      @include vp-767 {
        left: size(11px);
        width: size(197.34px);
        height: size(62px);
      }
    }
  }

  &__speech-text {
    margin: 0;
    font-family: $ff-inter;
    font-style: normal;
    font-weight: 500;
    font-size: size(12px);
    line-height: size(15px);
    text-align: center;
  }

  &__speech-icon {
    &--mob {
      display: none;
    }

    @include vp-767 {
      display: none;

      &--mob {
        display: block;
      }
    }
  }

  &__fish-icon {
    width: size(158.28px);
    height: size(140.08px);

    @include vp-767 {
      width: size(120.3px);
      height: size(125.2px);
    }

    svg {
      width: size(158.28px);
      height: size(140.08px);

      @include vp-767 {
        width: 100%;
        height: 100%;
      }
    }
  }
}
