/* stylelint-disable  function-no-unknown*/

.traffic {
  position: relative;
  z-index: 4;

  padding: size(420px) 0 size(407px);
  // overflow: hidden;

  @include vp-767 {
    z-index: 6;
    padding: size(390px) 0;
  }

  &__canvases {
    position: absolute;
    top: 0;

    width: size(1440px);
    height: size(1406px);

    @include vp-767 {
      left: -58%;
      top: size(-280px);

      width: size(792px);
      height: size(926px);
    }
  }

  &__flow-canvas {
    position: absolute;

    width: size(1440px);
    height: size(1406px);

    @include vp-767 {
      width: size(792px);
      height: size(926px);
    }

    &.is-hidden {
      display: none;
    }
  }

  &__wrapper {
    position: relative;
    z-index: 2;

    max-width: 45.2%;
    text-align: center;

    .title {
      margin-bottom: size(32px);
    }

    .text {
      max-width: size(482px);
      margin: 0 auto;
    }

    .text + .text,
    .text:not(:only-of-type) {
      margin-top: size(23px);
    }

    @include vp-767 {
      position: relative;
      max-width: unset;
      z-index: 5;

      .title {
        margin-bottom: size(23px);
      }

      &::after {
        content: "";
        position: absolute;
        top: size(-72px);
        left: 50%;
        bottom: size(-54px);
        z-index: -1;

        min-width: calc(100% + #{size(115px)});
        padding-bottom: calc(100% + #{size(115px)});
        background-color: $color-energy-yellow;
        border: size(2px) solid $color-default-black;
        border-radius: size(242px);
        transform: translateX(-50%);
      }
    }
  }

  &__logo-list {
    display: grid;
    grid-template-columns: repeat(4, size(121px));
    margin: 0;
    margin-top: size(124px);
    padding: 0;
    padding-left: size(20px);
    list-style-type: none;

    @include vp-767 {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: size(205px);
      margin: 0 auto;
      margin-top: size(25px);
      padding: 0;
    }
  }

  &__logo-item {
    position: relative;

    width: size(121px);
    height: size(113px);

    &:nth-child(1) {
      z-index: 4;
    }

    &:nth-child(2) {
      z-index: 3;
    }

    &:nth-child(3) {
      z-index: 2;
    }

    &:nth-child(4) {
      z-index: 1;
    }

    &:nth-child(5) {
      z-index: 8;
    }

    &:nth-child(6) {
      z-index: 7;
    }

    &:nth-child(7) {
      z-index: 6;
    }

    &:nth-child(8) {
      z-index: 5;
    }

    & > svg {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;

      width: size(144px);
      height: size(145px);
      transform-origin: left bottom;
      will-change: transform;

      @include vp-767 {
        display: none;
      }
    }

    @include vp-767 {
      width: size(41px);
      height: size(41px);
      margin-top: size(3px);
      overflow: hidden;
    }
  }

  &__content {
    position: absolute;
    top: size(1px);
    right: size(8px);
    z-index: 2;

    display: flex;
    justify-content: center;
    align-items: center;
    width: size(92px);
    height: size(92px);
    font-family: $ff-krona;
    font-style: normal;
    font-weight: 400;
    font-size: size(20px);
    line-height: size(23px);
    transform: translateZ(0);
    will-change: transform;

    svg {
      width: 100%;
      height: 100%;
    }

    @include vp-767 {
      top: 50%;
      left: 50%;

      width: size(56px);
      height: size(56px);
      font-size: size(12px);
      line-height: size(15px);
      transform: translate(-50%, -50%);
    }
  }

  &__tap {
    position: absolute;
    top: size(16px);
    right: 0;
    z-index: 4;

    @include vp-767 {
      top: size(-77px);
    }
  }

  &__tube {
    width: size(733px);
    height: size(193px);

    @include vp-767 {
      width: size(377px);
      height: size(88px);
    }
  }

  &__faucet {
    position: absolute;
    top: size(-175px);
    right: size(64px);

    width: size(460px);
    height: size(458px);

    @include vp-767 {
      top: size(-79px);
      right: size(94px);

      width: size(208px);
      height: size(208px);
    }
  }

  &__fish {
    position: absolute;
    z-index: 4;
    top: 55.8%;
    right: size(-56px);

    will-change: transform, opacity;

    @include vp-767 {
      top: 86%;
      right: size(-10px);
    }
  }

  &__fish-icon {
    width: size(138px);
    height: size(121px);

    svg {
      width: 100%;
      height: 100%;
    }

    @include vp-767 {
      width: size(92px);
      height: size(81px);
    }
  }

  &__speach-bubble {
    position: absolute;
    right: 107%;
    bottom: 118%;

    width: size(186px);
    padding: size(14px);

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;

      min-width: size(186px);
      width: auto;
      min-height: size(72px);
      height: 100%;
      transform: translate(-50%, -47%);
    }

    @include vp-767 {
      top: size(-62px);
      left: size(-194px);

      width: size(182px);

      svg {
        width: size(182px);
      }
    }
  }

  &__speach-text {
    margin: 0;
    font-family: $ff-inter;
    font-style: normal;
    font-weight: 500;
    font-size: size(12px);
    line-height: size(15px);
    text-align: center;
  }


  &__bacterium {
    position: absolute;
    z-index: 4;
    left: 50%;
    opacity: 0;
    bottom: size(990px);

    @include vp-767 {
      left: 47%;
      bottom: size(370px);
    }

    .bacterium__wrapper {
      transform: rotate(-50deg) translateZ(0);
    }

    &--cpm {
      // bottom: size(710px);
      left: 51.5%;

      @include vp-767 {
        // bottom: size(260px);
        left: 48%;
      }

      .bacterium__wrapper {
        transform: rotate(45deg) translateZ(0);
      }
    }

    &--cpa {
      // bottom: size(750px);
      left: 51%;

      @include vp-767 {
        left: 48%;
        // bottom: size(200px);
      }

      .bacterium__wrapper {
        transform: rotate(-17deg) translateZ(0);

        @include vp-767 {
          transform: rotate(-40deg) translateZ(0);
        }
      }
    }
  }

  &__divider {
    left: 51%;
    bottom: -4%;

    @include vp-767 {
      bottom: -65%;
    }
  }
}
