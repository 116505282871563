/* stylelint-disable  function-no-unknown*/

.custom-toggle {
  position: relative;
  font-size: 18px;
  line-height: 27px;
  font-weight: 300;

  display: flex;
  width: 500px;
  padding: 14px 0 10px 36px;

  @media screen and (max-width: 767px) {
    padding: 14px 0 10px 0;
    width: 100%;
  }

  &:last-of-type {
    padding: 14px 0 58px 36px;

    @media screen and (max-width: 767px) {
      padding: 14px 0 58px 0;
    }
  }

  input {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;

    clip: rect(0 0 0 0);
  }

  label {
    position: relative;

    padding-left: 52px;

    cursor: pointer;

    span {
      margin-bottom: 0;
      margin-left: 0;
    }
  }

  &--checkbox {
    .custom-toggle__icon {
      position: absolute;
      top: 0;
      left: 0;

      width: 28px;
      height: 28px;

      border: 1.5px solid #2c39f2;

      transition: border 0.3s ease;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;

        width: 20px;
        height: 20px;

        background-color: rgba(#2c39f2, 0);

        transform: translate(-50%, -50%);

        transition: background-color 0.3s ease;
      }
    }
  }

  &--radio {
    .custom-toggle__icon {
      position: absolute;
      top: 0;
      left: 0;

      width: 28px;
      height: 28px;

      border: 1px solid #2c39f2;
      border-radius: 50%;

      transition: border 0.3s ease;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;

        width: 20px;
        height: 20px;

        color: rgba(255, 255, 255, 0);

        border-radius: 50%;
        transform: translate(-50%, -50%);

        transition: color 0.3s ease;
      }
    }
  }

  input:checked + .custom-toggle__icon {
    border: 1.5px solid #2c39f2;

    &::before {
      background-color: #2c39f2;
    }
  }

  &.is-invalid {
    label span {
      color: #ff1553;
    }
  }

  .input-message {
    position: absolute;
    right: 0;
    bottom: -5px;

    &.is-invalid {
      color: #ff0000;
    }

    &.is-valid {
      color: #15ab07;
    }
  }
}
