body {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

html:not(.has-scroll-smooth) {
  .wrapper {
    overflow: hidden auto;
    max-height: calc(100 * var(--vh, 1vh));
  }
}

.wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  /* stylelint-disable-next-line */
  min-height: calc(100 * var(--vh, 1vh));

  .header,
  .footer {
    flex-shrink: 0;
  }

  @include vp-767 {
    max-width: 100%;
  }

  main {
    flex-grow: 1;
  }
}

.noise {
  z-index: -10;
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;

  pointer-events: none;

  canvas {
    width: 100%;
    height: 100%;
    opacity: 0.06;
  }
}

.rouble {
  /* stylelint-disable */
  font-weight: 400 !important;
  /* stylelint-enable */
  font-family: $ff-rouble;
  /* stylelint-disable */
  text-transform: lowercase !important;
  /* stylelint-enable */
}

.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;

  white-space: nowrap;

  border: 0;

  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.scroll-lock-ios {
  position: fixed;

  overflow: hidden;
}

.scroll-lock {
  overflow: hidden;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.no-transition {
  /* stylelint-disable-next-line */
  transition: none !important;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

// [data-animate],
// [data-tap],
// [data-faucet],
// [data-rainbow] {
//    transform: translateZ(0);
//   will-change: transform, opacity, scale;

//   h2, p, a, button {
//     transform: translateZ(0);
//     will-change: transform, opacity, scale;
//   }
// }

// [data-bacterium] {
//   transform: translateZ(0);
//   will-change: bottom;
// }

// [data-straight-flow] {
//   transform: translateZ(0);
//   will-change: transform, opacity;
// }
