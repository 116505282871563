/* stylelint-disable  function-no-unknown*/

.development {
  position: relative;
  z-index: 5;

  display: flex;
  flex-direction: column;
  padding: size(125px) 0 size(167px);

  @include vp-767 {
    padding: size(410px) 0 size(171px);
    z-index: 7;
  }

  .hidden-desk {
    display: none;

    @include vp-767 {
      display: block;
    }
  }

  .hidden-mb {
    @include vp-767 {
      display: none;
    }
  }

  &__wrapper {
    position: relative;
    z-index: 2;

    max-width: 41.8%;
    text-align: center;

    &--ml {
      max-width: 46.7%;
      margin-left: auto;
    }

    .title {
      margin-bottom: size(28px);
    }

    .text {
      max-width: size(340px);
      margin: 0 auto;
    }

    .text + .text,
    .text:not(:only-of-type) {
      margin-top: size(23px);
    }

    .btn__text {
      padding: size(6px) size(26px);
    }

    @include vp-767 {
      position: relative;
      max-width: unset;
      z-index: 10;

      .title {
        margin-bottom: size(23px);
      }

      .text {
        margin-bottom: size(40px);
      }

      .btn__text {
        padding: size(3px) size(21px);
      }

      &::after {
        content: "";
        position: absolute;
        top: size(-76px);
        left: 50%;
        bottom: size(-64px);
        z-index: -1;

        min-width: calc(100% + #{size(58px)});
        padding-bottom: calc(100% + #{size(58px)});
        background-color: $color-energy-yellow;
        border: size(2px) solid $color-default-black;
        border-radius: size(218px);
        transform: translateX(-50%);
      }
    }
  }

  &__text {
    margin: 0;
    margin-top: size(15px);
    margin-right: size(11px);
    font-family: $ff-krona;
    font-style: normal;
    font-weight: 400;
    font-size: size(14px);
    line-height: size(23px);
    text-align: center;

    @include vp-767 {
      margin-top: size(11px);
      margin-right: 0;
      font-family: $ff-inter;
      font-size: size(12px);
      line-height: size(24px);
      color: rgba($color-default-black, 0.5);
    }
  }

  &__slider {
    margin: size(120px) 0 size(137px);

    @include vp-767 {
      margin: size(111px) 0 0;
    }
  }

  &__decorate-block {
    position: relative;
    margin-bottom: size(45px);

    @include vp-767 {
      margin-bottom: size(224px);
    }
  }

  &__canvases {
    @include vp-767 {
      top: size(80px);
      left: -58%;
    }
  }

  &__canvases {
    width: size(1440px);
    height: size(1469px);

    @include vp-767 {
      width: size(792px);
      height: size(956px);
    }
  }

  &__flow-canvas {
    position: absolute;
    z-index: -1;
    width: size(1440px);
    height: size(1469px);

    @include vp-767 {
      width: size(792px);
      height: size(956px);
    }
  }

  &__bacterium {
    position: absolute;
    z-index: 4;
    left: 44%;
    bottom: size(1150px);
    opacity: 0;

    @include vp-767 {
      z-index: 1;
      left: 45%;
      bottom: size(670px);
    }

    .bacterium__wrapper {
      transform: rotate(65deg) translateZ(0);
    }

    &--apps {
      left: 43%;

      @include vp-767 {
        z-index: 2;
      }

      .bacterium__wrapper{
        transform: rotate(-61deg) translateZ(0);
      }
    }

    &--landing {
      left: 41%;
      z-index: 9;
      bottom: size(1000px);

      @include vp-767 {
        bottom: size(670px);
      }

      .bacterium__wrapper {
        transform: rotate(65deg) translateZ(0);
      }

      .bacterium__detals-wrap  {
        @include vp-767 {
          left: size(-150px);

          .bacterium__wrapper {
            transform: rotate(47deg) translateZ(0);
          }
        }
      }
    }

    &--marketplaces {
      left: 41%;
      z-index: 9;
      bottom: size(1100px);


      @include vp-767 {
        left: 39%;
        bottom: size(670px);
      }

      .bacterium__wrapper {
        transform: rotate(-74deg) translateZ(0);

        @include vp-767 {
          transform: rotate(-50deg) translateZ(0);
        }

      }

      .bacterium__detals-wrap {
        @include vp-767 {
          left: size(-130px);
        }
      }
    }
  }

  &__divider {
    left: 49%;
    bottom: -3%;

    @include vp-767 {
      bottom: -36%;
    }
  }
}
