/* stylelint-disable  function-no-unknown*/

.blot {
  canvas {
    width: 100%;
    height: 100%;
  }

  &--blue {
    .blot__canvas {
      width: size(384px);
      height: size(384px);

      @include vp-767 {
        width: size(192px);
        height: size(192px);
      }
    }
  }

  &--red {
    .blot__canvas {
      width: size(358px);
      height: size(358px);

      @include vp-767 {
        width: size(192px);
        height: size(192px);
      }
    }
  }

  &--yellow {
    .blot__canvas {
      width: size(466px);
      height: size(466px);

      @include vp-767 {
        width: size(233px);
        height: size(233px);
      }
    }
  }

  &--black {
    .blot__canvas {
      width: size(1218px);
      height: size(1137px);

      @include vp-767 {
        width: size(532px);
        height: size(497px);
      }
    }
  }
}
