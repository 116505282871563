.valve-canvases {
  position: absolute;
  top: 0;

  @include vp-767 {
    top: 0;
  }

  &__item {
    &.is-hidden {
      display: none;
    }
  }

}
