/* stylelint-disable  function-no-unknown*/

.bacterium {
  width: max-content;
  cursor: pointer;
  padding: size(25px);
  -webkit-tap-highlight-color: rgba($color-default-white, 0);

  @include vp-767 {
    min-height: 20px;
    padding: 0 size(14px);
  }

  &.hidden {
    .bacterium__wrapper {
      transform: rotate(0) translateZ(0);
    }
  }


  &:hover .bacterium__wrapper {
    transform: rotate(0) translateZ(0);
  }

  &:hover .bacterium__detals-baloon {
    width: size(254px);
    height: size(83px);
    opacity: 1;
    transition: opacity $trans-600;
    transition-delay: 0.45s;
    transform: translateZ(0);
  }

  &__wrapper {
    position: relative;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 size(18px);
    max-width: size(255px);
    min-height: size(26px);

    background-color: $color-energy-yellow;
    border-radius: 22px;
    border: 2px solid $color-default-black;

    will-change: transform;

    transition: transform $trans-600;

    @include vp-767 {
      min-height: size(20px);
      padding: 0 size(15px);
    }
  }

  &__text {
    font-family: $ff-krona;
    font-weight: 400;
    font-size: size(14px);
    line-height: size(23px);
    margin: 0;

    @include vp-767 {
      font-size: size(10.5px);
      line-height: size(17px);
    }
  }

  &__detals-wrap {
    position: absolute;
    z-index: 8;
    bottom: size(37px);
    left: size(-194px);

    @include vp-767 {
      bottom: size(24px);
      left: size(-170px);
    }
  }

  &__detals {
    font-family: $ff-inter;
    font-weight: 500;
    font-size: size(12px);
    line-height: size(15px);
    z-index: 1;
    width: max-content;
    position: relative;
    text-align: center;
    max-width: size(200px);
    margin: size(24px) 0 size(31px);
    max-height: size(30px);
    overflow: hidden;
    transform: translateZ(0);
  }

  &__detals-baloon {
    width: 0;
    height: 0;
    opacity: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateZ(0);
    will-change: opacity;

    svg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.bacterium--ui-kit {
  margin: 200px auto 100px;

  .bacterium__wrapper {
    transform: rotate(61deg) translateZ(0);
  }
}
