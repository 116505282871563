/* stylelint-disable  function-no-unknown*/

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  transform: scale(1.2);
  visibility: hidden;
  opacity: 0;

  transition: opacity $trans-modal,
    transform $trans-modal,
    visibility $trans-modal;

  pointer-events: none;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__wrapper {
    position: relative;

    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    padding: size(28px) size(40px);

    @include vp-767 {
      padding: size(62px) size(14px) size(62px) size(18px);
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background: rgba($color-default-black, 0.20);

    cursor: pointer;
  }

  &__content {
    position: relative;
    width: 100%;

    @include vp-767 {
      width: 100%;
    }
  }

  &__close-btn {
    position: absolute;
    top: size(19px);
    right: size(19px);
    z-index: 1;

    width: size(24px);
    height: size(24px);
    padding: 0;

    background: $color-transparent;
    border: none;
    cursor: pointer;

    transition: opacity $trans-default;
    -webkit-tap-highlight-color: rgba($color-default-white, 0);

    svg {
      width: 100%;
      height: 100%;
    }

    @include hover {
      opacity: 0.7;
    }

    @include vp-767 {
      top: size(14px);
      right: size(14px);
    }
  }

  &--responsive {
    .modal__content {
      width: 100%;
    }
  }

  &--fit-content {
    .modal__content {
      width: auto;

      @include vp-767 {
        width: 100%;
      }
    }
  }

  &--no-scale {
    transform: none;
  }

  &--preload {
    transition: none;
  }

  &.is-active {
    transform: scale(1);
    visibility: visible;
    opacity: 1;

    pointer-events: auto;
  }

  &--success {
    .modal__content {
      width: size(772px);
      min-height: size(738px);
      padding: size(66px) size(66px) size(65px) size(63px);

      background: $color-energy-yellow;
      border: 3px solid $color-default-black;
      box-shadow: size(-6px) size(4px) 0 $color-default-black;

      @include vp-767 {
        width: 100%;
        min-height: unset;
        padding: size(61px) size(17px) size(50px) size(17px);
      }
    }

    .modal__img {
      width: size(634px);
      height: size(340px);

      @include vp-767 {
        width: size(303px);
        height: size(338px);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .modal__close-btn {
      top: size(15px);
      right: size(15px);

      @include vp-767 {
        top: size(9px);
        right: size(9px);
      }
    }

    .modal__description {
      margin-bottom: size(42px);

      @include vp-767 {
        margin-bottom: size(43px);
      }
    }
  }
}
