/* stylelint-disable  function-no-unknown*/

.feedback-form {
  padding-top: size(80px);
  padding-bottom: size(40px);

  position: relative;
  z-index: 1;
  transform: translateZ(0);

  &__wrapper {
    min-height: size(712px);
    background: $color-energy-yellow;
    border: 3px solid $color-default-black;
    box-shadow: size(-6px) size(4px) 0 $color-default-black;
    padding: size(69px) size(67px) size(90px) size(67px);
    display: flex;
    position: relative;

    @include vp-767 {
      flex-direction: column;
      padding: size(21px) size(18px) size(201px);
    }
  }

  &__main-area {
    width: 65%;
    margin-right: size(20px);

    @include vp-767 {
      margin-right: unset;
      width: 100%;
      margin-bottom: size(54px);
    }
  }

  &__title {
    font-weight: 700;
    font-size: size(40px);
    line-height: size(54px);
    margin: 0 0 size(25px) 0;

    @include vp-767 {
      font-size: size(24px);
      line-height: size(32px);
      margin: 0 0 size(15px) 0;
    }
  }

  &__text {
    font-family: $ff-inter;
    font-weight: 400;
    font-size: size(16px);
    line-height: size(24px);
    margin: 0 0 size(50px) 0;
    max-width: size(560px);
    opacity: 0.5;

    @include vp-767 {
      max-width: 100%;
      margin: 0 0 size(30px) 0;
    }
  }

  &__add-area {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 43%;
    margin-bottom: size(180px);

    @include vp-767 {
      max-width: unset;
      margin-bottom: unset;
      margin-right: unset;
      width: 100%;
    }
  }

  &__channel {
    width: 100%;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include vp-767 {
      margin-right: unset;
    }
  }

  &__channel-img {
    position: relative;
    margin-bottom: size(17px);
    align-self: center;

    @include vp-767 {
      margin-bottom: size(9px);
    }
  }

  &__channel-img-container {
    width: size(176px);
    height: size(176px);
    border-radius: 50%;
    border: size(3px) solid $color-default-black;
    overflow: hidden;
    background: $color-transparent;
    transition: border $trans-default;

    @include vp-767 {
      width: size(124px);
      height: size(124px);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__channel-img-decor {
    position: absolute;
    width: size(27px);
    height: size(27px);
    bottom: size(5px);
    right: size(22px);

    @include vp-767 {
      width: size(18px);
      height: size(18px);
      bottom: size(4px);
      right: size(16px);
    }

    svg {
      display: block;
      transform: translateZ(0);
      width: 100%;
      height: 100%;
    }
  }

  &__decor-area {
    position: absolute;
    right: size(-2px);
    bottom: size(-3px);
  }

  &__decor-container {
    width: size(687px);
    height: size(266px);

    @include vp-767 {
      width: size(343px);
      height: size(133px);
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }

    img {
      display: block;
      object-fit: contain;
      height: 100%;
      width: auto;
    }
  }

  &__form {
    max-width: size(546px);

    @include vp-767 {
      max-width: 100%;
    }
  }

  &__channel-link {
    display: flex;
    flex-direction: column;
    transition: color $trans-default;

    @include hover-focus {
      color: $color-slugger;

      .feedback-form__channel-img-container {
        border-color: $color-slugger;
      }
    }
  }

  &__channel-name {
    font-size: size(18px);
    font-weight: 700;
    display: grid;
    min-width: size(140px);
    grid-template-columns: min-content 100%;
    max-width: 100%;
    padding-left: size(4px);
    align-self: center;

    @include vp-767 {
      font-size: size(14px);
      min-width: unset;
      margin-left: size(-16px);
      padding-left: 0;
    }

    svg {
      display: block;
      width: size(32px);
      height: size(32px);
      flex-grow: 0;
      order: -1;
      margin-top: auto;
      margin-bottom: auto;
    }

    span {
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      padding-left: size(10px);
      margin-top: auto;
      margin-bottom: auto;
      transform: translateZ(0);
    }
  }


  &__error-message {
    font-family: $ff-inter;
    color: $color-red-orange-2;
    font-size: size(16px);
    font-weight: 400;
    opacity: 0;
    transition: opacity $trans-default;
    margin: size(12px) 0 0;

    &.is-active {
      opacity: 1;
    }

    @include vp-767 {
      font-size: size(12px);
      font-weight: 400;
      line-height: size(14px);
      margin: size(6px) 0 0;
    }
  }

  &--modal {
    width: size(769px);
    padding: 0;

    @include vp-767 {
      width: 100%;
    }

    .container {
      padding: 0;
    }

    .feedback-form__add-area {
      display: none;
    }

    .feedback-form__decor-area {
      display: none;
    }

    .feedback-form__wrapper {
      padding: size(66px) size(62px) size(33px) size(63px);
      min-height: unset;

      @include vp-767 {
        padding: size(20px) size(17px) size(16px) size(17px);
        min-height: unset;
      }
    }

    .feedback-form__main-area {
      width: 100%;
      margin: 0;

      @include vp-767 {
        margin-bottom: 0;
      }
    }

    .feedback-form__form {
      max-width: unset;
    }
  }
}
