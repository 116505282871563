// Vendor
// ---------------------------------

@import "vendor/normalize";
@import "vendor/locomotive-scroll.scss";
// Swiper 7.4.1
@import "vendor/swiper";

// Global
// ---------------------------------

@import "keyframes";
@import "variables";
@import "mixins";
@import "functions";
@import "global/fonts";
@import "global/reboot";
@import "global/utils";
@import "global/container";

// Blocks
// ---------------------------------

@import "blocks/header";
@import "blocks/footer";
@import "blocks/modal";
@import "blocks/divider";
@import "blocks/btn";
@import "blocks/valve-canvases";
@import "blocks/custom-input";
@import "blocks/custom-toggle";
@import "blocks/custom-select";
@import "blocks/slider";
@import "blocks/input-upload";
@import "blocks/preloader-block";
@import "blocks/title";
@import "blocks/text";
@import "blocks/plate";
@import "blocks/intro";
@import "blocks/feedback-form";
@import "blocks/grinder";
@import "blocks/retention-tools";
@import "blocks/traffic";
@import "blocks/development";
@import "blocks/analytics";
@import "blocks/game-plan";
@import "blocks/splash";
@import "blocks/blot";
@import "blocks/more-tools";
@import "blocks/ventil";
@import "blocks/marketing";
@import "blocks/shaker";
@import "blocks/bacterium";
@import "blocks/scroll";


// ---------------------------------

// ❗❗❗ запрещено использовать изображения в css, касается как jpg \ png, так и svg

// ❗ обязательно используйте mixin hover-focus для отключения ховеров на тач устройствах
/* @mixin hover-focus {
    @media (hover: hover) {
      &:hover:not(.focus-visible) {
        @content;
      }
    }

    &.focus-visible:focus {
      @content;
    }
  }
*/
/*  @include hover-focus {
      opacity: 0.8;
    }
*/
// но не используем для текстовых полей ( input, textarea )
// так же в сборке есть отдельный миксин для hover
/*@mixin hover {
    @media (hover: hover) {
      &:hover:not(.focus-visible) {
        @content;
      }
    }
  }
*/
// для focus
/*@mixin focus {
    &.focus-visible:focus {
      @content;
    }
  }
*/
// и для active
/*@mixin active {
    &.focus-visible:active {
      @content;
    }
  }
*/
// адаптив пишем внутри каждого класса (смотрим container.scss)

// для фикса проблем с vh на iOS в сборке подключен скрипт
// используя vh на проекте задавайте их также как в примере в utils.scss

// для любых transition обязательно указывайте transition-property
// transition: $trans-default ❌ ---> transition: color $trans-default ✅
