/* stylelint-disable  function-no-unknown*/

.ventil {
  position: relative;
  z-index: 10;
  display: flex;
  margin-top: size(177px);
  transform: translateZ(0);
  will-change: transform;

  &__wrap {
    position: relative;
    left: 0;

    @include vp-767 {
      left: size(-140px);
    }
  }

  &__tap {
    width: size(795px);
    height: size(192px);

    @include vp-767 {
      width: size(369px);
      height: size(87px);
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__vent {
    width: size(460px);
    height: size(459px);
    position: absolute;
    left: size(104px);
    top: size(-178px);
    transform: translateZ(0);
    will-change: transform;

    @include vp-767 {
      width: size(206px);
      height: size(206px);

      left: size(109px);
      top: size(-81px);
    }

    img {
      width: 100%;
      height: 100%;
    }

    svg {
      width: size(125px);
      height: size(125px);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @include vp-767 {
        width: size(54px);
        height: size(54px);
      }
    }
  }
}

.ventil--right {
  justify-content: flex-end;

  .ventil__wrap {
    left: 0;

    @include vp-767 {
      left: size(124px);
    }
  }

  .ventil__tap {
    width: size(733px);
    height: size(193px);

    @include vp-767 {
      width: size(330px);
      height: size(87px);
    }
  }

  .ventil__vent {
    left: size(211px);

    @include vp-767 {
      left: size(54px);
    }
  }
}

.ventil--more {
  .ventil__wrap {
    left: size(-130px);
    top: size(50px);

    @include vp-767 {
      top: size(-80px);
      left: size(-205px);
    }
  }

  .ventil__vent {
    left: size(189px);
    top: size(-178px);

    @include vp-767 {
      left: size(150px);
      top: size(-81px);
    }
  }
}

.ventil--analytics {
  .ventil__wrap {
    left: size(-113px);
    top: size(20px);

    @include vp-767 {
      top: 0;
      left: size(-173px);
    }
  }

  .ventil__vent {
    left: size(186px);

    @include vp-767 {
      left: size(111px);
    }
  }
}

.ventil--right.ventil--retention {
  .ventil__wrap {
    top: size(100px);
    left: size(47px);

    @include vp-767 {
      left: size(154px);
      top: size(-10px);
    }
  }

  .ventil__vent {
    left: size(165px);

    @include vp-767 {
      left: size(33px);
    }
  }
}

.ventil--development {
  .ventil__wrap {
    top: size(40px);

    @include vp-767 {
      top: size(35px);
      left: size(-146px);
    }
  }
}

.ventil--traffick {
  .ventil__wrap {
    top: size(5px);

    @include vp-767 {
      top: size(25px);
      left: size(121px);
    }
  }
}
