/* stylelint-disable  function-no-unknown*/

.plate {
  position: relative;
  transform: translateZ(0);

  will-change: transform, scale;

  max-width: size(405px);
  min-height: size(114px);

  padding: size(38px) size(50px);

  background: $color-energy-yellow;
  border: size(3px) solid $color-default-black;
  box-shadow: size(-4px) size(10px) 0 $color-default-black;

  @include vp-767 {
    max-width: size(195px);
    min-height: size(70px);

    padding: size(15px) size(15px) size(13px) size(15px);

    border: size(3px) solid $color-default-black;
  }

  &__text {
    transform: translateZ(0);
    font-weight: 700;
    font-size: size(14px);
    line-height: size(19px);
    text-align: center;

    @include vp-767 {
      font-size: size(10px);
      line-height: size(14px);
    }
  }
}
