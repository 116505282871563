/* stylelint-disable  function-no-unknown*/

.retention-tools {
  position: relative;
  z-index: 2;
  width: 100%;
  min-height: size(800px);
  margin-bottom: size(320px);

  @media (min-width: ($vp-768 - 1)) and (max-width: $vp-1024) {
    @media (orientation: portrait) {
      top: 0;
      margin-bottom: size(-10px);
    }
  }

  @include vp-767 {
    position: relative;
    z-index: 4;
    margin-bottom: 0;
  }

  &__sticky-container {
    position: relative;
    margin-top: size(-153px);
    // margin-top: -22vh;
    min-height: size(2140px);

    @media (min-width: ($vp-768 - 1)) and (max-width: $vp-1024) {
      @media (orientation: portrait) {
        top: 0;
        margin-top: 0;
      }
    }

    @include vp-1023 {
      top: 0;
      margin-top: 0;
    }

    @include vp-767 {
      top: -22vh;
      min-height: size(1400px);
    }
  }

  &__sticky {
    display: flex;
    align-items: center;
  }

  &__carousel-trigger {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  &__decorate-block {
    position: relative;
    padding-top: size(200px);
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: size(90px);

    @include vp-767 {
      margin-bottom: size(141px);
      padding-top: size(90px);
      padding-bottom: size(50px);
    }
  }

  &__decorate-img {
    position: relative;
  }

  &__decorate-img-animate {
    position: absolute;
    left: size(163px);
    top: size(-177px);
    width: size(460px);
    height: size(460px);

    @include vp-767 {
      width: size(206px);
      height: size(206px);
      left: size(234px);
      top: size(-79px);
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__decorate-img-static {
    width: size(686px);
    height: size(193px);

    @include vp-767 {
      width: size(375px);
      height: size(86px);
      position: relative;
      right: size(-164px);
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__carousel-block {
    display: flex;
    justify-content: flex-end;
    position: relative;
    width: max-content;
    transform: translateZ(0);
    will-change: transform;
  }

  &__carousel-element {
    position: relative;
    width: size(330px);
    height: size(330px);
    background: $color-default-black;
    transform: translateZ(0);
    will-change: transform;

    @include vp-767 {
      width: size(191px);
      height: size(191px);
    }
  }

  &__carousel-element-1 {
    transform: rotate(-13.41deg) translateZ(0);
  }

  &__carousel-element-2 {
    transform: rotate(8.24deg) translateY(size(21px)) translateZ(0);

    @include vp-767 {
      transform: rotate(8.24deg) translateY(size(11px)) translateX(size(-5px)) translateZ(0);
    }
  }

  &__carousel-element-3 {
    transform: rotate(0) translateZ(0);
  }

  &__carousel-element-4 {
    transform: rotate(-3.53deg) translateY(size(112px)) translateX(-29px) translateZ(0);

    @include vp-767 {
      transform: rotate(-3.53deg) translateY(size(48px)) translateX(-29px) translateZ(0);
    }
  }

  &__backing {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $color-energy-yellow;
    border: 3px solid $color-default-black;
    z-index: -1;
    top: -5px;
    right: -5px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__text-block {
    position: relative;
    margin-top: size(-90px);

    @include vp-767 {
      padding-top: size(94px);
      padding-bottom: size(67px);
      overflow: hidden;
    }
  }

  &__text-block-container {
    padding-top: size(3px);
    width: 42.7%;
    display: flex;
    flex-direction: column;
    align-items: center;


    @include vp-767 {
      width: unset;
      padding-top: size(9px);
      padding-bottom: size(40px);
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: size(-92px);
        left: 50%;
        bottom: size(-52px);
        z-index: -1;

        min-width: calc(100% + #{size(58px)});
        padding-bottom: calc(100% + #{size(58px)});
        background-color: $color-energy-yellow;
        border: size(2px) solid $color-default-black;
        border-radius: size(228px);
        transform: translateX(-50%);
      }
    }
  }

  &__slogan {
    text-align: center;
    margin: 0 0 size(29px) 0;
    max-width: size(500px);

    @include vp-767 {
      font-size: size(20px);
      line-height: size(28px);
      margin: 0 0 size(24px) 0;
      max-width: size(340px);
    }

    span {
      color: $color-wewak;
    }
  }

  &__text {
    font-family: $ff-inter;
    font-weight: 400;
    font-size: size(16px);
    line-height: size(24px);
    text-align: center;
    color: $color-default-black;
    opacity: 0.5;
    max-width: size(500px);
    margin: 0;

    @include vp-767 {
      max-width: size(343px);
    }
  }

  &__canvases {
    top: size(290px);
    width: size(1440px);
    height: size(2548px);
    z-index: -1;

    @include vp-767 {
      width: size(792px);
      height: size(1846px);
      left: -58%;
      top: size(-220px);
    }
  }

  &__flow-canvas {
    position: absolute;
    width: size(1440px);
    height: size(2548px);

    @include vp-767 {
      width: size(792px);
      height: size(1846px);
    }

    // canvas {
    //   width: size(1440px);
    //   height: size(2548px);
    // }
  }

  &__divider {
    transform: translateX(-47%);

    @include vp-767 {
      display: none;
    }
  }
}
