/* stylelint-disable  function-no-unknown*/

.analytics {
  position: relative;
  z-index: 3;

  display: flex;
  flex-direction: column;
  padding: 0 0 size(134px);
  // overflow: hidden; TODO: ПРОВЕРИТЬ, ЧТО ЛОМАЕТ

  @include vp-767 {
    padding: 0;
    z-index: 5;
  }

  &__wrapper {
    position: relative;
    z-index: 2;

    max-width: 44.4%;
    text-align: center;

    &--ml {
      margin-left: auto;
    }

    .title {
      margin-bottom: size(19px);
    }

    .text {
      max-width: size(482px);
      margin: 0 auto;
    }

    .text + .text,
    .text:not(:only-of-type) {
      margin-top: size(23px);
    }

    @include vp-767 {
      position: relative;
      max-width: unset;

      .title {
        margin-bottom: size(23px);
      }

      .text {
        margin-bottom: size(40px);
      }

      &::after {
        content: "";
        position: absolute;
        top: size(-92px);
        left: 50%;
        bottom: size(-52px);
        z-index: -1;

        min-width: calc(100% + #{size(58px)});
        padding-bottom: calc(100% + #{size(58px)});
        background-color: $color-energy-yellow;
        border: size(2px) solid $color-default-black;
        border-radius: size(228px);
        transform: translateX(-50%);
      }
    }
  }

  &__slider {
    margin-top: size(111px);

    @include vp-767 {
      margin-top: size(110px);
    }
  }

  &__tap {
    position: absolute;
    top: size(264px);
    left: size(-55px);
    z-index: 1;

    width: size(795px);
    height: size(458px);

    svg {
      width: 100%;
      height: 100%;
    }

    @include vp-767 {
      top: size(120px);
      left: size(-148px);

      width: size(377px);
      height: size(208px);
    }
  }

  &__tube {
    width: size(795px);
    height: size(192px);

    @include vp-767 {
      width: size(377px);
      height: size(88px);
    }
  }

  &__faucet {
    position: absolute;
    top: size(-177px);
    left: size(129px);

    width: size(460px);
    height: size(458px);

    @include vp-767 {
      top: size(-79px);
      left: size(84px);

      width: size(208px);
      height: size(208px);
    }
  }

  &__decorate-block {
    position: relative;

    @include vp-767 {
      margin-bottom: size(137px);
    }
  }

  &__canvases {
    width: size(1440px);
    height: size(1546px);

    @include vp-767 {
      left: -58%;
      top: size(-502px);
      width: size(792px);
      height: size(1579px);
    }
  }


  &__flow-canvas {
    position: absolute;
    z-index: -1;
    top: 0;

    width: size(1440px);
    height: size(1546px);

    @include vp-767 {
      width: size(792px);
      height: size(1579px);
    }
  }

  &__divider {
    left: 48%;

    @include vp-767 {
      display: none;
    }
  }
}
