/* stylelint-disable  function-no-unknown*/

.shaker {
  position: relative;
  z-index: -1;

  display: flex;
  justify-content: center;
  align-items: center;

  min-height: size(1491px);
  margin-top: size(-270px);
  // padding-bottom: size(309px);

  .container {
    padding: 0;
  }

  @include vp-767 {
    margin-top: size(-250px);
    padding-bottom: size(544px);
  }

  &__body {
    position: absolute;
    z-index: 1;
    top: 0;
    left: calc(50% - #{size(180px)});

    width: size(360px);
    height: size(1691px);
    margin: 0 auto;
    transform: scale(0.8);

    @include vp-767 {
      left: calc(50% - #{size(100px)});
      transform: scale(0.9);

      width: size(200px);
      height: size(1440px);
    }
  }

  &__wrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    height: 100%;
    clip-path: polygon(0% 100%, 0 size(77px), 2% size(57px), 8% size(38px), 16% size(25px), 26% size(14px), 38% size(5px), 50% 0, 62% size(5px), 74% size(14px), 84% size(25px), 92% size(38px), 98% size(57px), 100% size(77px), 100% 100%);
    overflow: hidden;
    transform: translateZ(0);
    will-change: height;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: size(-5px);
      right: size(-5px);
      z-index: 1;

      height: size(85px);
      background-image: linear-gradient(180deg, rgba($color-half-dutch-white, 1) 0%, rgba($color-half-dutch-white, 0) 100%);
      clip-path: polygon(0% 100%, 0 size(57px), 2% size(47px), 8% size(38px), 16% size(25px), 26% size(14px), 38% size(5px), 50% 0, 62% size(5px), 74% size(14px), 84% size(25px), 92% size(38px), 98% size(47px), 100% size(57px), 100% 100%);
      transform: translateZ(0);
    }
  }

  // стили для открытия/закрытия
  .clip-path,
  .border,
  &__canvas-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .border {
    width: size(360px);
    height: size(1690px);

    svg {
      width: 100%;
      height: 100%;
    }

    @include vp-767 {
      width: size(200px);
      height: size(1440px);
    }
  }

  &__canvas-wrapper {
    clip-path: url("#shakerClipPath");
    width: size(358px);
    height: size(1720px);
    overflow: hidden;

    @include vp-767 {
      width: size(198px);
      height: size(1440px);
    }

    video {
      object-fit: fill;
      filter: hue-rotate(0deg);
      transform: translateZ(0);
    }

    video {
      width: size(358px);
      height: size(1990px);

      @include vp-767 {
        width: size(198px);
        height: size(1440px);
      }
    }
  }

  &__plate {

    position: absolute;
    z-index: 1;
    top: size(422px);
    left: 50%;

    transform: translateX(-48%);

    max-width: size(541px);
    padding: size(30px) size(22px) size(38px);
    text-align: center;

    .title {
      margin-bottom: size(23px);
    }

    @include vp-767 {
      max-width: size(347px);
      width: 100%;
      top: size(400px);
      transform: translateX(-50%);

      .title {
        margin-bottom: size(16px);
      }
    }
  }

  &__shaker-container {
    margin-top: size(625px);

    @include vp-767 {
      margin-top: size(790px);
    }
  }

  &__lottie-trigger {
    position: relative;
    z-index: 1;
    width: size(2544px);
    height: calc(#{size(2544px)} * 1.5);
    transform: translateX(#{size(-540px)}) scale(0.7);


    @include vp-767 {
      width: size(1440px);
      height: calc(#{size(1440px)} * 2.5);
      transform: translateX(#{size(-525px)}) scale(0.8);
    }
  }

  &__shaker-canvas {
    position: absolute;
    width: size(2544px);
    height: size(2544px);
    transform: translateZ(0);
    will-change: transform;

    @media (max-width: $vp-1024) {
      @media (orientation: portrait) {
        position: sticky;
        top: size(-330px);
      }
    }

    @include vp-767 {
      position: sticky;
      top: size(-330px);
      width: size(1440px);
      height: size(1440px);
    }

    canvas {
      width: 100%;
      height: 100%;
      transform: translateZ(0);
      will-change: transform;
    }
  }

  &__horse-overflow,
  &__unicorn-overflow {
    position: absolute;
    top: size(642px);
    left: 0;
    width: size(800px);
    height: size(460px);
    overflow: hidden;
    transform: translateZ(0);
    will-change: transform;

    @include vp-767 {
      top: size(742px);
      left: -85%;

      width: size(536px);
      height: size(250px);
    }
  }

  &__unicorn-overflow {
    left: unset;
    right: 0;

    @include vp-767 {
      z-index: 1;
      right: -103%;
      left: unset;
    }
  }

  &__unicorn-stroke {
    display: none;

    @include vp-767 {
      position: absolute;
      top: size(742px);
      left: size(209px);
      z-index: 2;

      width: size(21px);
      height: size(236px);
      display: block;
      opacity: 0;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__horse,
  &__unicorn {
    position: absolute;
    top: 0;
    left: 0;

    width: size(619px);
    height: size(460px);

    @include vp-767 {
      width: size(336px);
      height: size(250px);
      left: 25%;
    }

    canvas {
      width: 100%;
      height: 100%;
    }
  }

  &__unicorn {
    left: unset;
    right: 0;

    @include vp-767 {
      left: unset;
      z-index: 1;
      right: 30%;
      transform: rotate(-11deg);
    }
  }
}
