/* stylelint-disable  function-no-unknown*/

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  padding: size(40px) 0;
  background-color: $color-transparent;
  pointer-events: none;

  @include vp-767 {
    padding: size(19px) 0;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: size(10px);
  }

  &__logo {
    width: size(355px);
    height: size(27px);
    margin-left: size(-31px);
    pointer-events: all;

    svg {
      width: 100%;
      height: 100%;
    }

    &--mobile {
      display: none;
    }

    @include vp-767 {
      display: none;
      width: size(75.1px);
      height: size(25.3px);
      margin-left: 0;

      &--mobile {
        display: block;
      }
    }
  }

  &__toggle {
    display: none;
    justify-content: center;
    align-items: center;
    width: size(48px);
    height: size(40px);
    margin-left: size(-4px);
    padding: 0;
    color: $color-default-black;
    background-color: $color-transparent;
    border: none;
    box-shadow: none;

    cursor: pointer;
    pointer-events: all;

    svg {
      width: 100%;
      height: auto;
    }

    @include vp-767 {
      display: flex;
    }
  }
}

.header--preloader.is-hidden {
  opacity: 0;
  pointer-events: none;
}
